import React, {forwardRef, FunctionComponent, useCallback, useMemo, useState} from 'react'
import {Checkbox, Form} from 'antd'
import FormModal, {ConsolidatedFormProps} from "components/forms/Form.Modal"
import {without} from "lodash"
import Language from "language"
import styled from "styled-components"
import UserManagerAceHelp from "components/admin/user/UserManager.AceHelp"
import {useFields} from "hooks/useFields"
import {ACES, Environment, User, WorkspacesForAce} from "components/admin/user/UserManager.types"
import {ACESConfModel, SimplifiedUserAcesFormProps} from "components/forms/chart/types"
import {FormInstance} from "antd/lib/form/hooks/useForm"

type Props = SimplifiedUserAcesFormProps & ConsolidatedFormProps<ACESConfModel> & { form: FormInstance }

// eslint-disable-next-line react/display-name
const FormComponent = forwardRef<FormInstance, Props>((props, ref) => {
  const {userCanManageAdvancedFeature, isBironUser, environment, availableWorkspacesForAce} = props
  const data: { [p: string]: string[] | undefined } = useMemo(() => ({
    [`environment:${environment.id}`]: [],
    ...availableWorkspacesForAce.reduce((acc, cur) => ({...acc, [`workspace:${cur.id}`]: []}), {}),
    // eslint-disable-next-line no-warning-comments
    ...props.data,
  }), [availableWorkspacesForAce, environment.id, props.data])
  const fields = useFields(props.data, props.errors)
  const [oldRoles, setOldRoles] = useState(Object.fromEntries(Object.entries(availableWorkspacesForAce).map(([key, w]) => ([
    key,
    data[`workspace:${w.id}`],
  ]))))

  const clientOptions = useMemo(() =>
      buildRoleOptions(['WORKSPACE_USER', 'WORKSPACE_MANAGER', 'ENVIRONMENT_ADMIN', userCanManageAdvancedFeature && isBironUser && 'ENVIRONMENT_SUPERADMIN'].filter(Boolean) as string[]),
    [userCanManageAdvancedFeature, isBironUser])
  const workspaceOptions = useMemo(() => buildRoleOptions(['WORKSPACE_USER', 'WORKSPACE_MANAGER']), [])

  return <StyledForm ref={ref} fields={fields} name={'editUserAces'} form={props.form}
                     onValuesChange={(changedValues: Record<string, string[]>) => {
                       const valuesToReSet = Object.entries(changedValues).reduce((acc, [target, roles]) => {
                         const oldWorkspaceRoles = oldRoles[target] || []
                         let newRoles
                         // if WORKSPACE_MANAGER is added, also add WORKSPACE_USER
                         if (!oldWorkspaceRoles.includes('WORKSPACE_MANAGER') && roles.includes('WORKSPACE_MANAGER') && !roles.includes('WORKSPACE_USER')) {
                           newRoles = [...roles, 'WORKSPACE_USER']
                         } else
                           // if WORKSPACE_USER is removed, also remove WORKSPACE_MANAGER
                         if (oldWorkspaceRoles.includes('WORKSPACE_USER') && !roles.includes('WORKSPACE_USER') && roles.includes('WORKSPACE_MANAGER')) {
                           newRoles = without(roles, 'WORKSPACE_MANAGER')
                         }
                         setOldRoles({
                           ...oldRoles,
                           ...{
                             ...changedValues,
                             [target]: newRoles,
                           },
                         })
                         return newRoles ? {
                           ...acc,
                           [target]: newRoles,
                         } : acc
                       }, {})
                       if (Object.keys(valuesToReSet).length > 0) {
                         setTimeout(props.form.setFieldsValue, 0, valuesToReSet)
                       }
                       props.onValuesChange(changedValues)
                     }} labelCol={{span: 8}} wrapperCol={{span: 16}}>
    <div>
      <StrongFormItem label={Language.get(`enum.aceTargetType.environment`, environment)} name={`environment:${environment.id}`}>
        <Checkbox.Group options={clientOptions}/>
      </StrongFormItem>
      <UserAceHelpContainer>
        <UserManagerAceHelp/>
      </UserAceHelpContainer>
    </div>
    {availableWorkspacesForAce.map(w =>
      <Form.Item key={`workspace${w.id}`} label={Language.get(`enum.aceTargetType.workspace`, w)} name={`workspace:${w.id}`}>
        <Checkbox.Group options={workspaceOptions}/>
      </Form.Item>,
    )}
  </StyledForm>
})

interface UserAcesFormProps {
  user?: User
  aces: ACES[]
  getPermission: (key: string) => boolean
  environment: Environment
  availableWorkspacesForAce: WorkspacesForAce[]
  onConfirm: (newAces: ACES[]) => Promise<void>
  onCancel: () => void
}

const UserAcesForm: FunctionComponent<UserAcesFormProps> = ({
                                                              user,
                                                              aces,
                                                              getPermission,
                                                              environment,
                                                              availableWorkspacesForAce,
                                                              onConfirm,
                                                              onCancel,
                                                            }) => {
  const userCanManageAdvancedFeature = getPermission('userCanManageAdvancedFeature')
  const [form] = Form.useForm()

  const data: ACESConfModel = useMemo(() => (aces || []).reduce((acc, {target, roles}) => ({
      ...acc,
      [target]: roles,
    }), {}),
    [aces])
  const formProps: SimplifiedUserAcesFormProps = useMemo(() => ({
      environment,
      availableWorkspacesForAce,
      userCanManageAdvancedFeature,
      isBironUser: Boolean(user && user.bironUser),
    }),
    [environment, availableWorkspacesForAce, userCanManageAdvancedFeature, user],
  )

  const handleOnConfirm = useCallback((newFormData: ACES[]) => onConfirm(
      Object.entries(newFormData)
        .reduce((acc: any, [target, roles]) => [
          ...acc,
          {
            target,
            roles: roles || [],
          },
        ], [])
        .filter(Boolean),
    ),
    [onConfirm])

  return <FormModal<ACESConfModel, SimplifiedUserAcesFormProps> {...{
    defaultTitle: Language.get('admin.user.formAcesTitle', {user: user || {}}),
    open: !!user,
    data,
    width: 800,
    renderFormComponent: (props) => <FormComponent {...{...props, form}}/>,
    onConfirm: handleOnConfirm,
    onCancel: () => {
      form.resetFields()
      onCancel()
    },
    formProps,
  }} />
}

export default UserAcesForm

const StyledForm = styled(Form)`
  position: relative;
`

const StrongFormItem = styled(Form.Item)`
  font-weight: bold;
`

const UserAceHelpContainer = styled.div`
  position: absolute;
  height: 19px;
  right: 0;
  top: 8px;
`
const buildRoleOptions = (roles: string[]) => roles.map(role => ({
  value: role,
  label: Language.get(`enum.role.${role}`),
}))
