import React, {FunctionComponent, useMemo} from "react"
import {GenericChartTypes} from "types/widgets"
import {
  ChartBarIcon,
  ChartPieIcon,
  CheckCircleIcon,
  InboxIcon,
  MenuAlt2Icon,
  PresentationChartLineIcon,
  TableIcon,
} from "@heroicons/react/outline"
import {IconContainer} from "components/common/IconContainer"
import {ExtendedWidgetTypes, WidgetTypes} from "commons/dashboard/dashboard.types"

interface Props {
  type: ExtendedWidgetTypes
  size?: number
  margin?: number
}

const WidgetIcon: FunctionComponent<Props> = ({type, size, margin}) => {
  const chartIcon = useMemo(() => {
    switch (type) {
      case WidgetTypes.DIVIDER:
        return <MenuAlt2Icon/>
      case GenericChartTypes.AREA:
        return <InboxIcon/>
      case GenericChartTypes.LINE:
        return <PresentationChartLineIcon/>
      case GenericChartTypes.BOXES:
        return <svg width={size} height={size} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4.5 6H12.5" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="1.41421" strokeLinecap="round"
                strokeLinejoin="round"/>
          <path
            d="M5.14907 14.75C4.21189 14.2939 3.74331 13.5746 3.74331 12.5921C3.74331 12.1184 3.86496 11.6842 4.10826 11.2895C4.35157 10.8947 4.71653 10.5833 5.20314 10.3553C5.68975 10.1184 6.2845 10 6.98738 10C7.68126 10 8.2715 10.1184 8.75811 10.3553C9.25373 10.5833 9.6232 10.8947 9.8665 11.2895C10.1098 11.6842 10.2315 12.1184 10.2315 12.5921C10.2315 13.0833 10.1008 13.5175 9.83947 13.8947C9.58715 14.2632 9.24923 14.5482 8.82569 14.75C9.33934 14.9342 9.74485 15.2368 10.0422 15.6579C10.3396 16.0702 10.4883 16.557 10.4883 17.1184C10.4883 17.6886 10.3396 18.193 10.0422 18.6316C9.74485 19.0702 9.33033 19.4079 8.79866 19.6447C8.26699 19.8816 7.66323 20 6.98738 20C6.31153 20 5.70778 19.8816 5.17611 19.6447C4.65345 19.4079 4.24343 19.0702 3.94606 18.6316C3.64869 18.193 3.5 17.6886 3.5 17.1184C3.5 16.5482 3.64869 16.057 3.94606 15.6447C4.24343 15.2325 4.64444 14.9342 5.14907 14.75ZM8.75811 12.7763C8.75811 12.2851 8.60041 11.9079 8.28502 11.6447C7.96962 11.3816 7.53707 11.25 6.98738 11.25C6.4467 11.25 6.01867 11.3816 5.70327 11.6447C5.38787 11.9079 5.23017 12.2895 5.23017 12.7895C5.23017 13.2281 5.39238 13.5877 5.71679 13.8684C6.05021 14.1404 6.47374 14.2763 6.98738 14.2763C7.50103 14.2763 7.92456 14.136 8.25798 13.8553C8.5914 13.5746 8.75811 13.2149 8.75811 12.7763ZM6.98738 15.4079C6.39264 15.4079 5.90602 15.5526 5.52755 15.8421C5.15808 16.1228 4.97335 16.5307 4.97335 17.0658C4.97335 17.5658 5.15358 17.9737 5.51403 18.2895C5.87448 18.5965 6.3656 18.75 6.98738 18.75C7.60015 18.75 8.08226 18.5921 8.4337 18.2763C8.79416 17.9605 8.97438 17.557 8.97438 17.0658C8.97438 16.5395 8.78965 16.1316 8.42018 15.8421C8.05973 15.5526 7.58213 15.4079 6.98738 15.4079Z"
            fill="currentColor"/>
          <path
            d="M17.486 19.7632L13.8499 15.6711V19.7632H12.309V10.6184H13.8499V14.7895L17.4995 10.6184H19.4324L15.3503 15.1974L19.5 19.7632H17.486Z"
            fill="currentColor"/>
        </svg>
      case GenericChartTypes.BARS:
        return <ChartBarIcon/>
      case WidgetTypes.TARGET:
        return <CheckCircleIcon/>
      case GenericChartTypes.TABLES:
        return <TableIcon/>
      case GenericChartTypes.PIE:
        return <ChartPieIcon/>
      case GenericChartTypes.SCATTER:
        return <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="6.16156" cy="14.8405" r="1.85687" stroke="currentColor" strokeWidth="1.5"/>
          <path
            d="M6.99781 4.35462C6.99781 6.01073 5.65527 7.35328 3.99915 7.35328C2.34303 7.35328 1.00049 6.01073 1.00049 4.35462C1.00049 2.6985 2.34303 1.35596 3.99915 1.35596C5.65527 1.35596 6.99781 2.6985 6.99781 4.35462Z"
            stroke="currentColor" strokeWidth="1.5"/>
          <circle cx="14.2219" cy="8.266" r="4.72437" stroke="currentColor" strokeWidth="1.5"/>
        </svg>
      default:
        return null
    }
  }, [size, type])

  return <IconContainer size={size} margin={margin}>{chartIcon}</IconContainer>
}

export default WidgetIcon
