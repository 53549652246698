import {useCallback, useMemo} from "react"
import {QuestionMarkCircleIcon} from "@heroicons/react/outline"
import Language from "language"
import {FilterInputType, FilterInputTypeBySemanticType} from "types/filter"
import {
  ConfFilter,
  ConfFilterRangeTypes,
  ConfFilterScalarTypes,
  DictEntries,
  FilterDtoDetailTypes,
  FilterNegationType,
  FilterOperator,
  FilterType,
  filterTypeToIcon,
  Pattern,
} from "components/forms/selector/comps/box/filters"
import {isEmpty} from "lodash"

export const useCurrentIcon = (filter: { predicate: { negate: boolean } }) => useMemo(() => filterTypeToIcon.get(filter.predicate.negate ? FilterNegationType.negate : FilterNegationType.default) ?? QuestionMarkCircleIcon, [filter.predicate.negate])

export const escapePattern = (pattern: string) => pattern
  .replaceAll("\\", '\\\\').replaceAll("%", '\\%')
  .replaceAll("_", '\\_')

export const unescapePattern = (pattern: string) => pattern.replaceAll("\\%", '%')
  .replaceAll("\\_", '_')
  .replaceAll("\\\\", '\\')

export const getFilterValue = (filter: Pick<ConfFilter, "predicate">) => {
  switch (filter.predicate.operator) {
    case FilterOperator.IN:
      return (filter.predicate.value as DictEntries).entries.map(entry => entry.id).join(', ')
    case FilterOperator.Like: {
      const {pattern} = filter.predicate.value as Pattern
      if (isIncludePattern(pattern)) {
        return unescapePattern(pattern.substring(1, pattern.length - 1))
      } else if (isStartWithPattern(pattern)) {
        return unescapePattern(pattern.substring(0, pattern.length - 1))
      } else {
        return unescapePattern(pattern.substring(1, pattern.length))
      }
    }
    case FilterOperator.Match:
      return unescapePattern((filter.predicate.value as Pattern).pattern)
    case FilterOperator.BETWEEN:
      return ""
    case FilterOperator.LT:
    case FilterOperator.LTE:
    case FilterOperator.GT:
    case FilterOperator.EQ:
    case FilterOperator.GTE:
      return ""
    default: {
      // eslint-disable-next-line no-case-declarations
      const exhaustiveCheck: never = filter.predicate
      return exhaustiveCheck
    }
  }
}
export const getFilterResume = (filter: Pick<FilterDtoDetailTypes, "predicate">): string => {
  switch (filter.predicate.operator) {
    case FilterOperator.IN:
      return (filter.predicate.value as DictEntries).entries.map(entry => entry.id).join(', ')
    case FilterOperator.Like: {
      const {pattern} = filter.predicate.value as Pattern
      if (isIncludePattern(pattern)) {
        return `${Language.get(`filter.type.${FilterInputType.INCLUDE}.title`)}: ${unescapePattern(pattern.substring(1, (filter.predicate.value as Pattern).pattern.length - 1))}`
      } else if (isStartWithPattern(pattern)) {
        return `${Language.get(`filter.type.${FilterInputType.START_WITH}.title`)}: ${unescapePattern(pattern.substring(0, (filter.predicate.value as Pattern).pattern.length - 1))}`
      } else if (isEndWithPattern(pattern)) {
        return `${Language.get(`filter.type.${FilterInputType.END_WITH}.title`)}: ${unescapePattern(pattern.substring(1, (filter.predicate.value as Pattern).pattern.length))}`
      } else {
        return pattern
      }
    }
    case FilterOperator.Match:
      return `${Language.get(`filter.operator.${FilterOperator.Match}`)}: ${unescapePattern((filter.predicate.value as Pattern).pattern)}`
    case FilterOperator.BETWEEN:
      return `${Language.get(`filter.operator.${FilterOperator.BETWEEN}`, {min: filter.predicate.value.minInclusive, max: filter.predicate.value.maxInclusive})}`
    case FilterOperator.GT:
      return `${Language.get(`filter.operator.${FilterOperator.GT}`, filter.predicate.value.value)}`
    case FilterOperator.EQ:
      return `${Language.get(`filter.operator.${FilterOperator.EQ}`, filter.predicate.value.value)}`
    case FilterOperator.GTE:
      return `${Language.get(`filter.operator.${FilterOperator.GTE}`, filter.predicate.value.value)}`
    case FilterOperator.LTE:
      return `${Language.get(`filter.operator.${FilterOperator.LTE}`, filter.predicate.value.value)}`
    case FilterOperator.LT:
      return `${Language.get(`filter.operator.${FilterOperator.LT}`, filter.predicate.value.value)}`
    default: {
      // eslint-disable-next-line no-case-declarations
      const exhaustiveCheck: never = filter.predicate
      return exhaustiveCheck
    }
  }
}

export const useFilterValue = (filter: Pick<FilterDtoDetailTypes, "predicate">) => useMemo(() => getFilterResume(filter), [filter])

export const useFilterTypeOptions = (filter: Pick<ConfFilter, "type" | "dataType">) => useMemo(() => FilterInputTypeBySemanticType[filter.type === FilterType.metric ? "metric" : filter.dataType].map(type => (
  {
    label: Language.get(`filter.type.${type}.title`),
    value: type,
  })
), [filter])

export const isRegexValid = (regexString: string) => {
  try {
    RegExp(regexString)
    return true
  } catch (error) {
    return false
  }
}
export const useIsFilterValueValid = (type: FilterInputType, value: string) => useMemo(() => {
  switch (type) {
    case FilterInputType.REGEX:
      return isRegexValid(value)
    default:
      return true
  }
}, [value, type])

export const useIsValueNumeric = (value: string): boolean => useMemo(() => {
  return value ? !isNaN(Number(value)) : true
}, [value])

export const useIsMinUnderMax = (minValue: string, maxValue: string): boolean => useMemo(() => {
  return !isEmpty(minValue) && !isEmpty(maxValue) ? Number(minValue) < Number(maxValue) : true
}, [minValue, maxValue])

export const isFilterEmpty = (filter: FilterDtoDetailTypes | ConfFilter) => {
  switch (filter.predicate.operator) {
    case FilterOperator.IN:
      return filter.predicate.value.entries.length === 0
    case FilterOperator.Like:
    case FilterOperator.Match:
      return !filter.predicate.value.pattern.replaceAll("%", "").trim()
    case FilterOperator.BETWEEN:
      return filter.predicate.value.maxInclusive === undefined
        || filter.predicate.value.minInclusive === undefined
    case FilterOperator.GT:
    case FilterOperator.EQ:
    case FilterOperator.GTE:
    case FilterOperator.LTE:
    case FilterOperator.LT:
      return filter.predicate.value.value === null || filter.predicate.value.value === undefined
    default: {
      const exhaustiveCheck = filter
      return exhaustiveCheck
    }
  }
}

export const useGetFilterValue = (filterType: FilterInputType) => useCallback((value: string, localType = filterType) => {
  switch (localType) {
    case FilterInputType.INCLUDE:
      return `%${value === "" ? " " : value}%`
    case FilterInputType.END_WITH:
      return `%${value === "" ? " " : value}`
    case FilterInputType.START_WITH:
      return `${value === "" ? " " : value}%`
    default:
      return value
  }
}, [filterType])

export const isIncludePattern = (pattern: string) => pattern.startsWith('%') && pattern.endsWith('%') && !(pattern.startsWith("\\%") || pattern.endsWith("\\%"))
export const isEndWithPattern = (pattern: string) => pattern.startsWith('%') && !pattern.startsWith("\\%")
export const isStartWithPattern = (pattern: string) => pattern.endsWith('%') && !pattern.endsWith("\\%")
export const useGetFilterType = () => useCallback((localFilter: ConfFilter): FilterInputType => {
  switch (localFilter.predicate.operator) {
    case FilterOperator.IN:
      return FilterInputType.VALUE_SELECTION
    case FilterOperator.Like: {
      if (isIncludePattern(localFilter.predicate.value.pattern)) {
        return FilterInputType.INCLUDE
      } else if (isEndWithPattern(localFilter.predicate.value.pattern)) {
        return FilterInputType.END_WITH
      }
      return FilterInputType.START_WITH
    }
    case FilterOperator.Match:
      return FilterInputType.REGEX
    case FilterOperator.BETWEEN:
      return FilterInputType.BETWEEN
    case FilterOperator.GT:
      return FilterInputType.GREATER
    case FilterOperator.EQ:
      return FilterInputType.EQUAL
    case FilterOperator.GTE:
      return FilterInputType.GREATER_OR_EQ
    case FilterOperator.LTE:
      return FilterInputType.LOWER_OR_EQ
    case FilterOperator.LT:
      return FilterInputType.LOWER
    default: {
      const exhaustiveCheck = localFilter.predicate
      return exhaustiveCheck
    }
  }
}, [])

export const useIsFilterContainingLeadingOrTrailingSpace = (value: string) => useMemo(() => value.length > 0 && (value.startsWith(" ") || value.endsWith(" ")), [value])

export const formatFilterValueToHumanReadable = <T extends ConfFilterScalarTypes | ConfFilterRangeTypes>(filter: T, value: number | undefined): number | undefined => {
  switch (filter.type) {
    case FilterType.dimension:
      return value
    case FilterType.metric: {
      if (filter.reference.asPercentage) {
        return value === undefined ? (value ?? undefined) : (Number(value) * 100)
      }
      return value
    }
    default: {
      const exhaustiveCheck = filter as never
      return exhaustiveCheck
    }
  }
}

export const formatFilterValueToLego = <T extends ConfFilterScalarTypes | ConfFilterRangeTypes>(filter: T, value: number | undefined): number | undefined => {
  switch (filter.type) {
    case FilterType.dimension:
      return value
    case FilterType.metric: {
      if (filter.reference.asPercentage) {
        return value === undefined ? (value ?? undefined) : (value / 100)
      }
      return value
    }
    default: {
      const exhaustiveCheck = filter as never
      return exhaustiveCheck
    }
  }
}