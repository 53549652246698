import React, {memo, useCallback, useMemo, useState} from "react"
import Language from "language"
import {Tabs} from "antd"
import styled from "styled-components"
import {MetricSelectorValue} from "components/forms/selector/metrics/MetricSelector.types"
import {ChartTypeWithDisabledReason} from "components/forms/chart/types"
import {DataSelection} from "redux/models/currentDashboard"
import {PeriodTypes} from "types/period.types"
import {GenericChartTypes} from "types/widgets"
import {
  converterFilterToConfModel,
  formatFormDataToMetricDto,
  formatMetricDtoToFormData,
  validateFormDataValues,
} from "components/forms/chart/utils"
import MetaModel, {Dimension} from "classes/MetaModel"
import {buildMetricLabel} from "classes/workflows/query-workflows/genericDataSetUtil"
import PeriodIcon from 'themes/svg/outlined/date.svg?react'
import FilterIcon from 'themes/svg/outlined/filter.svg?react'
import GrowthIcon from 'themes/svg/outlined/growth.svg?react'
import DisplayIcon from 'themes/svg/outlined/display.svg?react'
import {IconContainer} from "components/common/IconContainer"
import {XIcon} from "@heroicons/react/outline"
import {
  useHasDisplayOptionApplied,
  useHasFilterApplied,
  useHasGrowthApplied,
  useHasPeriodApplied,
} from "components/metricDisplay/MetricDisplay.hooks"
import {SingleMetricSelectorValue} from "components/forms/selector/metrics/SingleMetricSelector.types"
import {
  ConfMetricWithActivationState,
  MetricOptionsFormKeys,
  PartialConfMetricWithActivationState,
} from "components/forms/selector/metrics/options/MetricOptionForm.types"
import MetricFiltersOptionSelector from "components/forms/selector/metrics/options/selectors/MetricFiltersOptionSelector"
import MetricPeriodOptionSelector from "components/forms/selector/metrics/options/selectors/MetricPeriodOptionSelector"
import MetricGrowthOptionSelector from "components/forms/selector/metrics/options/selectors/MetricGrowthOptionSelector"
import MetricDisplayOptionSelector from "components/forms/selector/metrics/options/selectors/MetricDisplayOptionSelector"
import MetricOptionsFormFooter from "components/forms/selector/metrics/options/MetricOptionsFormFooter"
import {isEqual} from "lodash"

interface MetricOptionsFormProps {
  environmentId: number
  availableDimensions: Dimension[]
  id: number
  value: MetricSelectorValue
  onValuesChange: (data: Partial<SingleMetricSelectorValue>) => void
  configuration?: ChartTypeWithDisabledReason
  dashboardSelection: DataSelection
  period?: PeriodTypes
  displayType?: GenericChartTypes
  canInvert: boolean
  dayDifference: number
  onCancel: () => void
  activeKey?: MetricOptionsFormKeys
  metaModel: MetaModel
}


// eslint-disable-next-line react/display-name
const MetricOptionsForm = memo<MetricOptionsFormProps>(({
                                                          id,
                                                          availableDimensions,
                                                          environmentId,
                                                          value,
                                                          configuration,
                                                          onValuesChange,
                                                          dashboardSelection,
                                                          displayType,
                                                          canInvert,
                                                          dayDifference,
                                                          period,
                                                          onCancel,
                                                          activeKey: originalActiveKey,
                                                          metaModel,
                                                                                  }) => {
  const [temporaryMetric, setTemporaryMetric] = useState<ConfMetricWithActivationState>(formatMetricDtoToFormData(value, availableDimensions))

  const hasGrowthApplied = useHasGrowthApplied({
    growth: temporaryMetric && temporaryMetric.growthType && temporaryMetric.growthPeriod ? {
      type: temporaryMetric.growthType,
      period: temporaryMetric.growthPeriod,
    } : undefined,
  })
  const hasFilterApplied = useHasFilterApplied({additionalFilters: temporaryMetric?.additionalFilters ?? []})
  const hasPeriodApplied = useHasPeriodApplied({overridePeriod: temporaryMetric?.overridePeriod})
  const hasDisplayOptionApplied = useHasDisplayOptionApplied({
    extraConf: {
      details: isEqual(temporaryMetric.details, value.extraConf?.details) ? temporaryMetric.details : undefined,
      units: temporaryMetric.units ?? undefined,
      decimals: isEqual(temporaryMetric.decimals, value.extraConf?.decimals) ? temporaryMetric.decimals : undefined,
      color: temporaryMetric.color ?? undefined,
    },
  })

  const onMetricChange = useCallback((metric: PartialConfMetricWithActivationState) => {
    const newValue = {
      ...temporaryMetric,
      ...metric,
    }
    setTemporaryMetric({
      ...newValue,
      additionalFilters: converterFilterToConfModel(newValue.additionalFilters, availableDimensions),
    } as ConfMetricWithActivationState)
  }, [availableDimensions, temporaryMetric])

  const onSave = useCallback(() => {
    if (temporaryMetric) {
      onValuesChange(formatFormDataToMetricDto(validateFormDataValues(temporaryMetric, value)))
    }
  }, [onValuesChange, temporaryMetric, value])

  const onReset = useCallback(() => {
    if (value) {
      setTemporaryMetric(old => ({
        ...old,
        growthType: undefined,
        growthPeriod: undefined,
        activated: false,
        additionalFilters: [],
        color: null,
        units: null,
        overridePeriod: undefined,
        overridePeriodLabel: undefined,
        additionalFiltersLabel: undefined,
        growthLabel: undefined,
        label: undefined,
      }))
    }
  }, [value])

  const title = useMemo(() => buildMetricLabel(temporaryMetric), [temporaryMetric])

  const [activeKey, setActiveKey] = useState(originalActiveKey)

  return <div>
    <Title data-testid="title">
      {title}
      <IconContainer clickable={true} onClick={onCancel}><XIcon/></IconContainer>
    </Title>
    <StyledTabs
      tabPosition={"left"}
      activeKey={activeKey}
      onChange={(key) => setActiveKey?.(key as MetricOptionsFormKeys)}
      items={[{
        active: activeKey === MetricOptionsFormKeys.filters,
        key: MetricOptionsFormKeys.filters,
        label: <HorizontalFlexContainer $modified={hasFilterApplied ? 1 : 0}>
          <IconContainer size={22}><FilterIcon/></IconContainer>
          {Language.get("configuration-metric-options.filters.title")}
        </HorizontalFlexContainer>,
        children: <MetricFiltersOptionSelector {...{
          environmentId,
          availableDimensions,
          temporaryMetric,
          onMetricChange,
        }} />
      }, {
        active: activeKey === MetricOptionsFormKeys.period,
        key: MetricOptionsFormKeys.period,
        label: <HorizontalFlexContainer $modified={hasPeriodApplied ? 1 : 0}>
          <IconContainer size={22}><PeriodIcon/></IconContainer>
          {Language.get("configuration-metric-options.period.title")}
        </HorizontalFlexContainer>,
        children: <MetricPeriodOptionSelector {...{
          environmentId,
          temporaryMetric,
          onMetricChange,
          metaModel,
        }} />
      }, {
        active: activeKey === MetricOptionsFormKeys.growth,
        key: MetricOptionsFormKeys.growth,
        label: <HorizontalFlexContainer $modified={hasGrowthApplied ? 1 : 0}>
          <IconContainer size={22}><GrowthIcon/></IconContainer>
          {Language.get("configuration-metric-options.growth.title")}
        </HorizontalFlexContainer>,
        children: <MetricGrowthOptionSelector {...{
          id,
          temporaryMetric,
          configuration,
          onMetricChange,
          dashboardSelection,
          displayType,
          canInvert,
          dayDifference,
          period,
        }} />
      }, {
        active: activeKey === MetricOptionsFormKeys.display,
        key: MetricOptionsFormKeys.display,
        label: <HorizontalFlexContainer $modified={hasDisplayOptionApplied ? 1 : 0}>
          <IconContainer size={22}><DisplayIcon/></IconContainer>
          {Language.get("configuration-metric-options.display.title")}
        </HorizontalFlexContainer>,
        children: <MetricDisplayOptionSelector {...{
          environmentId,
          configuration,
          temporaryMetric,
          onMetricChange,
          metaModel,
        }} />
      }]}
    >
    </StyledTabs>
    <MetricOptionsFormFooter
      onReset={onReset}
      onSave={onSave}
      onCancel={onCancel}/>
  </div>
})

export default MetricOptionsForm

const Title = styled.div`
    display: flex;
  font-weight: 600;
    padding: 4px 20px;
  border-bottom: 1px solid var(--main-background-color);
    justify-content: space-between;
    align-items: center;
`

const HorizontalFlexContainer = styled.div<{ $modified: number }>`
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
    gap: 13px;
    ${({$modified}) => $modified ? `color: var(--primary);` : ''}
`

const StyledTabs = styled(Tabs)`
  .ant-tabs-content {
    display: flex;
    flex-direction: column;
  }

    .ant-tabs-content {
        height: 100%;
        display: flex;
        justify-content: space-between;
    }

  .ant-tabs-tab-active {
    background-color: #D9DFFC;
  }

  &&& .ant-tabs-nav-wrap {
    padding-right: 10px !important;
  }
`
