import React, {FunctionComponent, useCallback, useMemo, useState} from "react"
import styled from "styled-components"
import Language from "language"
import {Button, Form, Input, notification, Popover, Radio, Result, Skeleton, Space} from "antd"
import {IconContainer} from "components/common/IconContainer"
import {AnswerQuality, AssistantInteractionDtoDetail, evaluateResponse, generateChartTitle} from "services/AiService"
import {ChartDetailWithoutLayout} from "components/widgetContainer/WidgetContainer"
import {CheckboxOptionType} from "antd/es/checkbox/Group"
import BB8 from "themes/svg/outlined/bb8.svg?react"
import FormRow from "components/forms/selector/metrics/options/FormRow"
import {isEmpty} from "lodash"

interface Props {
  chart: ChartDetailWithoutLayout,
  iconColor: string
  onConfirm: (newConf: ChartDetailWithoutLayout) => Promise<any>
}

interface FormValues {
  answerQuality: AnswerQuality,
  betterAnswer?: string,
  comment?: string,
}

export const TitleGenerator: FunctionComponent<Props> = ({
                                                           chart,
                                                           iconColor,
                                                           onConfirm,
                                                         }) => {

  const [open, setOpen] = useState(false)
  const [interactionResponse, setInteractionResponse] = useState<AssistantInteractionDtoDetail | undefined>(undefined)
  const [form] = Form.useForm<FormValues>()
  const [error, setError] = useState(null)

  const handleOpenChange = useCallback((newOpen: boolean) => {
    setOpen(newOpen)
    if (newOpen) {
      generateChartTitle(chart.id)
        .then((res) => {
          setInteractionResponse(res)
        })
        .catch((e) => {
          setError(e)
        })
    } else {
      setInteractionResponse(undefined)
      form.resetFields()
    }
  }, [chart.id, form])

  const options: CheckboxOptionType<string>[] = useMemo(() => [
    {label: 'Good', value: 'GOOD'},
    {label: 'Mediocre', value: 'MEDIOCRE'},
    {label: 'Bad', value: 'BAD'},
  ], [])

  const onFeedbackAndReplaceTitle = useCallback(() => {
    const values = form.getFieldsValue()
    form.validateFields().then(() => {
      form.resetFields()
      if (interactionResponse) {
        evaluateResponse(chart.id, values, interactionResponse?.id)
          .then(() => {
            notification.info({message: "Thank you for the feedback !"})
            onConfirm({
              ...chart,
              title: isEmpty(values.betterAnswer) ? interactionResponse.systemResponse : values.betterAnswer,
            }).then(() => {
              setOpen(false)
            }).catch(() => {
              setOpen(false)
            })
          })
          .catch((e) => {
            setError(e)
          })
      } else {
        setOpen(false)
      }
    })
  }, [chart, form, interactionResponse, onConfirm])

  const onFeedbackOnly = useCallback(() => {
    const values = form.getFieldsValue()
    form.validateFields().then(() => {
      form.resetFields()
      if (interactionResponse) {
        evaluateResponse(chart.id, values, interactionResponse?.id)
          .then(() => {
            notification.info({message: "Thank you for the feedback !"})
            setOpen(false)
          })
          .catch((e) => {
            setError(e)
          })
      } else {
        setOpen(false)
      }
    })
  }, [chart.id, form, interactionResponse])

  const title = useMemo(() => interactionResponse ? <TitleSuggestion>
    {interactionResponse.systemResponse}
  </TitleSuggestion> : <StyledSkeleton title={true} paragraph={false} active/>, [interactionResponse])

  const content = useMemo(() => error ? <Result
    status={"error"}
    title={Language.get("error-occurred")}
  /> : <StyledSpace direction={"vertical"}>
    <TitleContainer>
      <IconContainer size={23}><BB8/></IconContainer>
      <div>Title Suggestion :</div>
    </TitleContainer>
    {title}
    <FormContainer>
      <FormTitle>Feedback</FormTitle>
      <StyledForm
        title={"Feedback"}
        form={form}
        size={"small"}
      >
        <FormRow
          name={"answerQuality"}
          title={"Answer quality"}
          textSpan={8}
          contentSpan={15}
          contentMargin={2}
          rules={[{required: true, message: "Please enter a value"}]}>
          <RadioGroup options={options} optionType="button"/>
        </FormRow>
        <FormRow
          name={"betterAnswer"}
          title={"Better answer"}
          textSpan={8}
          contentSpan={15}
          contentMargin={2}>
          <Input placeholder={"Enter your answer"}/>
        </FormRow>
        <FormRow
          name={"comment"}
          title={"Comment"}
          textSpan={8}
          contentSpan={15}
          contentMargin={2}>
          <Input placeholder={"Enter your comment"}/>
        </FormRow>
      </StyledForm>
    </FormContainer>
    <div style={{display: "flex", justifyContent: "end", gap: 8}}>
      <Button onClick={onFeedbackOnly}>Send feedback and close</Button>
      <Button onClick={onFeedbackAndReplaceTitle} type={"primary"}>Use suggestion</Button>
    </div>
  </StyledSpace>, [error, form, onFeedbackOnly, onFeedbackAndReplaceTitle, options, title])

  return <Popover content={content} open={open}
                  trigger={"click"}
                  onOpenChange={handleOpenChange}
                  placement={"top"}
                  overlayStyle={{width: 550}}
                  overlayInnerStyle={{borderRadius: 8}}
  >
    <IconContainer margin={12} color={iconColor} size={23} clickable={true}><BB8/></IconContainer>
  </Popover>
}
const TitleContainer = styled.div`
    font-size: 16px;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 12px;
`

const FormContainer = styled.div`
    background-color: var(--popup-background);
    padding: 12px 15px;
    margin-bottom: 5px;
`

const FormTitle = styled.div`
    font-size: 14px;
    margin-bottom: 4px;
`

const StyledSpace = styled(Space)`
    padding: 10px;
    width: calc(100% - 20px);
`

const TitleSuggestion = styled.div`
    color: var(--primary);
    font-size: 16px;
    font-weight: 600;
    text-align: center;
`

const StyledSkeleton = styled(Skeleton)`
    height: 38px;

    h3 {
        margin-block-end: 0;
    }
`
const StyledForm = styled(Form)`
    display: flex;
    flex-direction: column;
    gap: 6px;

    &&& .ant-input {
        line-height: 27px;
    }

    &&& .ant-radio-group {
        height: 36px;
        display: flex;
        border: none;
    }

    &&& .ant-radio-button-wrapper-in-form-item {
        height: 36px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        border-top: 1px solid var(--main-background-color);
        border-bottom: 1px solid var(--main-background-color);
    }

    &&& .ant-radio-button-wrapper-in-form-item:nth-child(odd) {
        border: 1px solid var(--main-background-color);
    }
`

const RadioGroup = styled(Radio.Group)`
    line-height: 27px;
    height: 27px;

    &&& .ant-radio-button {
        line-height: 27px;
        align-items: center;
    }
`
