import React, {memo, useMemo, useState} from 'react'
import Language from 'language'
import HelpPopoverBody from 'components/widgets/topper/help-popover/HelpPopoverBody'
import {EffectiveConfTypes} from "components/charts/line/LineChart.types"
import {ChartDetailWithoutLayout} from 'components/widgetContainer/WidgetContainer'
import {TooltipPopover} from "components/tooltipPopover/TooltipPopover"
import {IconContainer} from "components/common/IconContainer"
import {QuestionMarkCircleIcon, XIcon} from "@heroicons/react/outline"
import styled from 'styled-components'
import {Button} from "antd"
import useClickPrevention from "hooks/useClickPrevention"
import {WidgetTypes} from "commons/dashboard/dashboard.types"

interface Props {
  effectiveConf: EffectiveConfTypes
  chart: ChartDetailWithoutLayout
  getBqlRequest: () => void
  onConfEdit?: () => any
}

const HelpPopover = memo<Props>(function HelpPopover({
                                                       effectiveConf,
                                                       chart,
                                                       getBqlRequest,
                                                       onConfEdit,
                                                     }) {
  const [isVisible, setIsVisible] = useState(false)
  const [handleBqlClick] = useClickPrevention({onClick: getBqlRequest})
  const isNexusQLAvailable = useMemo(() => onConfEdit && chart.type === WidgetTypes.GENERIC, [chart.type, onConfEdit])

  return effectiveConf
    ? <TooltipPopover size={"small"} content={Language.get("chart-definition-button")}>
      <TooltipPopover
      size={'large'}
      placement="leftTop"
      trigger="click"
      onOpenChange={(visible) => setIsVisible(visible)}
      open={isVisible}
      style={{
        minWidth: 784,
      }}
      content={<HelpPopoverBody effectiveConf={effectiveConf} chart={chart}/>}
      title={<TitleContent>
        {Language.get('chart-definition')}
        <FlexDiv>
          {isNexusQLAvailable && <Button
            type={"primary"}
            onClick={() => handleBqlClick()}>
            {Language.get("dashboard-nexusQlCopy-title")}
          </Button>
          }
          <div onClick={() => setIsVisible(false)}>
            <IconContainer clickable={true}><XIcon/></IconContainer>
          </div>
        </FlexDiv>
      </TitleContent>}>
        <div>
          <IconContainer clickable={true} hoverColor={"var(--primary-light)"} containerSize={28}><QuestionMarkCircleIcon/></IconContainer>
        </div>
      </TooltipPopover>
    </TooltipPopover>
    : null
})

export default HelpPopover

const TitleContent = styled.div`
    padding: 20px;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const FlexDiv = styled.div`
  display: flex;
  gap: 12px;
`
