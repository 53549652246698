import React, {forwardRef, FunctionComponent} from 'react'
import WrapperRoot from './WrapperRoot'
import MetricSelector, {Props as MetricSelectorProps} from "components/forms/selector/metrics/MetricSelector"
import {WrapperItem} from "components/forms/confItems/WrapperItem"
import {RuleObject} from "antd/lib/form"
import {ChartBarIcon} from "@heroicons/react/outline"
import {RuleRender} from "rc-field-form/lib/interface"

interface Props {
  children: React.ReactNode
  name: string
  rules?: (RuleObject | RuleRender)[]
}

export const MetricsWrapper: FunctionComponent<Props> = ({children, name, rules}) => (
  <WrapperRoot icon={ChartBarIcon}
               label="configuration-label-metrics-single"
               description="configuration-description-metrics">
    <WrapperItem name={name} rules={rules}>
      {children}
    </WrapperItem>
  </WrapperRoot>
)

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const MetricsField = forwardRef<any, MetricSelectorProps>(function Field({...props}, ref) {
  return <MetricSelector {...props} />
})
