import React, {PropsWithChildren} from "react"
import {Form} from 'antd'
import styled from "styled-components"
import {RuleObject, RuleRender} from "rc-field-form/lib/interface"

interface WrapperItemProps<T> extends PropsWithChildren {
  name: string
  rules?: (RuleObject | RuleRender)[]
  initialValue?: T
}

export const WrapperItem = <T, >({children, name, rules, initialValue}: WrapperItemProps<T>) => <FormItem name={name} rules={rules}
                                                                                                          initialValue={initialValue}>
  {children}
</FormItem>


const FormItem = styled(Form.Item)`
  margin-bottom: 0;
  width: 100%;
`
