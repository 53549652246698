import {MetricCacheElement, MetricTarget} from "components/forms/chart/types"

export const isBarColorOverridable = (slicerCount: number, metricCount: number) => {
  if (slicerCount === 0) {
    return true
  } else if (slicerCount === 1 && metricCount > 1) {
    return true
  }
  return false
}

export const isLineColorOverridable = (slicerCount: number) => {
  return slicerCount <= 1
}

export const replicateMetricsTarget = (metrics: Pick<MetricCacheElement, "isRatio" | "growth" | "format">[], affect: MetricTarget) => [...Array(metrics.length + 1).keys()].map(() => affect)