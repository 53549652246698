import API from './api'
import {PeriodTypes} from "types/period.types"

type Id = string | number

export const getPeriodsByClient = (environmentId: Id): Promise<PeriodTypes[]> => API.instanceData.get(`/period/byEnvironment/${environmentId}`)

export const create = (data: PeriodTypes, environmentId: number) => API.instanceData.post(`/period`, data, {
  params: {
    environmentId,
  },
})
export const update = (data: PeriodTypes) => API.instanceData.put(`/period/${data.id}`, data)
export const usage = (periodId: number): Promise<{ id: number }[]> => API.instanceData.get(`/period/${periodId}/usage`)

export const deletePeriod = (id: number) => API.instanceData.delete(`/period/${id}`)