import React, {forwardRef, FunctionComponent, PropsWithChildren} from 'react'
import WrapperRoot from './WrapperRoot'
import {WrapperItem} from "components/forms/confItems/WrapperItem"
import {FilterIcon} from "@heroicons/react/outline"
import DimensionFilterSelector from "components/forms/selector/filters/DimensionFilterSelector/DimensionFilterSelector"
import {RuleObject} from "antd/lib/form"
import {RuleRender} from "rc-field-form/lib/interface"

interface WrapperProps extends PropsWithChildren {
  name: string
  rules?: (RuleObject | RuleRender)[]
}

export const DimensionFiltersWrapper: FunctionComponent<WrapperProps> = ({children, name, rules}) => (
  <WrapperRoot {...{
    icon: FilterIcon,
    label: "configuration-label-filters",
    description: "configuration-description-filters",
  }}>
    <WrapperItem name={name} rules={rules}>
      {children}
    </WrapperItem>
  </WrapperRoot>
)

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const DimensionFiltersField = forwardRef<any, any>(function Field(props, ref) {
  return <DimensionFilterSelector {...props} />
})
