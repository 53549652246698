import {EffectiveConfMetric, EffectiveConfSlicerTypes} from "components/charts/line/LineChart.types"
import {Format} from "types/charts"
import {HTML_DIVIDER, tooltipBaseParameters} from "components/charts/Chart.tooltip"
import {useCallback, useMemo} from "react"
import {isArray} from "lodash"
import {formatForTooltip, formatToTwoDecimal} from "commons/format/formatter"
import {MetricDataTree} from "classes/MetricDataTree"
import {useGetMetricSeries} from "components/charts/scatter/ScatterChart.hooks"
import {isEmpty} from "commons/object.utils"

export const MAX_BUBBLE_SIZE = 166
export const MIN_BUBBLE_SIZE = 10

const getAverage = (array: number[]) =>
  array.reduce((sum, currentValue) => sum + currentValue, 0) / array.length

export const useFormatValue = (data: {
  label: string,
  data: [number, number, number, string][] | undefined
}[]) => useCallback((value: number) => {
  const totalScaleValues = data.flatMap(d => d.data?.flatMap(dd => dd[2]) ?? [])
  const maxValue = Math.max(...totalScaleValues)
  const avgValue = getAverage(totalScaleValues)
  const maxSize = (MAX_BUBBLE_SIZE / 3) * Math.min(3, maxValue / avgValue)
  const pointNbCoef = (0.18 * Math.sqrt(totalScaleValues.length)) + 0.82
  return Math.sqrt(value) * maxSize / Math.sqrt(maxValue) / pointNbCoef
}, [data])

export const useDatas = (parsedMetrics: MetricDataTree[]) => {
  const getMetricSeries = useGetMetricSeries(parsedMetrics)

  return useMemo((): {
    label: string
    containsHiddenSeries: boolean
    data: [number, number, number, string][],
  }[] => {
    if (parsedMetrics.length < 2) {
      return []
    }
    const slicer = parsedMetrics[0].getXAxisAt(0)
    return parsedMetrics.map(m => m.getSeries())[0].map((serie, serieIndex) => {
      const firstMetricValues = getMetricSeries(0, serieIndex)
      const secondMetricValues = getMetricSeries(1, serieIndex)
      const thirdMetricValues = getMetricSeries(2, serieIndex)

      const fullData = firstMetricValues?.values.map((v, i) => [
        firstMetricValues?.values[i] ?? 0,
        secondMetricValues?.values[i] ?? 0,
        thirdMetricValues?.values[i],
        slicer[i],
      ])

      const filteredData = fullData?.filter(value =>
        !(value[0] === 0 && value[1] === 0 && value[2] === undefined),
      ).map(([firstMetric, secondMetric, thirdMetric, firstSlicer]) => [
        firstMetric,
        secondMetric,
        thirdMetric ?? MIN_BUBBLE_SIZE,
        firstSlicer,
      ] as [number, number, number, string]) ?? []
      return {
        label: serie.label,
        containsHiddenSeries: Boolean(fullData && filteredData && fullData?.length !== filteredData.length),
        data: filteredData,
      }
    })

  }, [getMetricSeries, parsedMetrics])
}

export const scatterChartTooltip = (formats: Format[], effectiveConf: {
  metrics: Pick<EffectiveConfMetric, 'metricAlias'>[]
  slicers: EffectiveConfSlicerTypes[]
}) => ({
  ...tooltipBaseParameters(),
  formatter: (params: any) => {
    const tooltipString = []
    const value = isArray(params) ? params[0] : params
    const formatSlicer = (slicer: EffectiveConfSlicerTypes, val: string) => {
      switch (slicer.type) {
        case "dimension":
          return formatForTooltip(slicer.dimension.alias, val)
        case "date":
          return formatForTooltip("Date", val)
        default:
          return ""
      }
    }
    const getSlicerPart = (slicers: EffectiveConfSlicerTypes[]) => {
      const result = []
      if (slicers.length > 0) {
        result.push(formatSlicer(slicers[0], value.data[value.data.length - 1]))
      }
      if (slicers.length > 1) {
        result.push(formatSlicer(slicers[1], value.seriesName))
      }
      return result.join('')
    }
    if (effectiveConf.slicers.length > 0) {
      tooltipString.push(getSlicerPart(effectiveConf.slicers))
      tooltipString.push(HTML_DIVIDER)
    }
    tooltipString.push(effectiveConf.metrics.map((m, mi) => formatForTooltip(m.metricAlias, value.data[mi], formats[mi])).join(''))
    return `<div style="width: 350px; font-size: 10px;">${tooltipString.join(' ')}</div>`
  },
})

export const useIsThirdMetricWithZeroValue = (datas: { label: string, data: [number, number, number, string][] }[]) => useMemo(() => {
  return !isEmpty(datas.flatMap(d => d.data.flatMap(dd => dd[2])).find(el => (Number(el) ?? 0) <= 0))
}, [datas])

export const useComputeMaxWithOffset = (datas: { label: string, data: [number, number, number, string][] }[], dimensions: {
  width: number,
  height: number
}, maxAppliedBubbleValue: number) => useCallback((axeIndex: 0 | 1) => {
  const dataIndex = datas.flatMap(data => data.data.filter(d => d[2]).map(d => d[axeIndex]))
  const maxValue = Math.max(...dataIndex)
  return formatToTwoDecimal(maxValue + (maxValue / ((axeIndex === 0 ? dimensions.width : dimensions.height) / maxAppliedBubbleValue)))
}, [datas, dimensions.height, dimensions.width, maxAppliedBubbleValue])

export const useComputeMinWithOffset = (datas: { label: string, data: [number, number, number, string][] }[], dimensions: {
  width: number,
  height: number
}, maxAppliedBubbleValue: number) => useCallback((axeIndex: 0 | 1) => {
  const dataIndex = datas.flatMap(data => data.data.filter(d => d[2]).flatMap(d => d[axeIndex]))
  const minValue = Math.min(...dataIndex)
  return formatToTwoDecimal(minValue - (minValue / ((axeIndex === 0 ? dimensions.width : dimensions.height) / maxAppliedBubbleValue)))
}, [datas, dimensions.height, dimensions.width, maxAppliedBubbleValue])

