import {Col, Row} from "antd"
import React, {PropsWithChildren, ReactNode, useMemo} from "react"
import styled from "styled-components"
import {WrapperItem} from "components/forms/confItems/WrapperItem"
import {RuleObject, RuleRender} from "rc-field-form/lib/interface"

interface FormRowProps<T> {
  name: string
  title: string
  activated?: boolean
  initialValue?: T
  additionalComponent?: ReactNode
  textAlignment?: "center" | "start"
  contentSpan?: number
  textSpan?: number
  contentMargin?: number
  rules?: (RuleObject | RuleRender)[]
}

const FormRow = <T, >({
                        name,
                        title,
                        activated = true,
                        initialValue,
                        additionalComponent,
                        textAlignment = "center",
                        contentSpan = 17,
                        textSpan = 7,
                        contentMargin = 0,
                        rules,
                        children,
                      }: PropsWithChildren<FormRowProps<T>>) => {
  const required = useMemo(() => rules?.find(rule => typeof rule === "object" ? rule.required : false) ? 1 : 0, [rules])
  return <RowWithFixedHeight $activated={activated ? 1 : 0}>
    <TextCol span={textSpan} $textalignment={textAlignment} $required={required}>
      {title}
    </TextCol>
    <ContentCol span={contentSpan} $margin={contentMargin}>
      <WrapperItem name={name} initialValue={initialValue} rules={rules}>
        {children}
      </WrapperItem>
    </ContentCol>
    {additionalComponent}
  </RowWithFixedHeight>
}

export default FormRow

const RowWithFixedHeight = styled(Row)<{ $activated: number }>`
    ${({$activated}) => $activated ? '' : 'color: var(--medium-grey);'}
`
const TextCol = styled(Col)<{ $textalignment: string, $required: number }>`
    display: flex;
    align-items: ${({$textalignment}) => $textalignment};
    height: 40px;

    ${({$required}) =>
            $required ? ` &::before {
        display: inline-block;
        margin-inline-end: 4px;
        color: #ff4d4f;
        font-size: 14px;
        font-family: SimSun, sans-serif;
        line-height: 1;
        content: "*";
    }` : ""
    }
`

const ContentCol = styled(Col)<{ $margin: number }>`
    display: flex;
    align-items: center;
    margin: ${({$margin}) => $margin}px;
`